import { Box, Typography } from "@mui/material";
import { forwardRef, Ref } from "react";
import { Queue } from "../Backend/SplitzUpApp/splitzUpApp";
import { QUEUE_NAME_FOR_REVIEWED } from "../utils/constants";
import { transformQueueName, reverseMap } from "../utils/queueUtils";
import { TransactionDetails } from "./TransactionItem";
import TransactionItemV2 from "./TransactionItemV2";

export interface GroupTransactionDetails {
    date: string;
    transactionDetails: TransactionDetails[];
}

export interface GroupQueueDetails {
    queues: Queue[];
    onQueueChange: (date: string, trasactionId: string, queue: Queue) => void;
}

export interface TransactionGroupProps {
    onTransactionClick: (id: string) => void;
    groupTransactionDetails: GroupTransactionDetails; 
    groupQueueDetails: GroupQueueDetails;
}

export const TransactionGroupV2 = forwardRef((props: TransactionGroupProps, ref: Ref<HTMLDivElement>) => {
    const reviewQueue = props.groupQueueDetails.queues.find(queue => queue.name === QUEUE_NAME_FOR_REVIEWED);

    const updatedQueues: Queue[] = props.groupQueueDetails.queues.map(queue => {
        return {
            id: queue.id,
            name: transformQueueName(queue.name)
        }
    })
    
    return (
        <Box ref={ref} sx={{mb: 1.5}}>
            <Box 
                sx={{
                    pb: 0,
                    pt: 0
                }}
            >
                <Typography variant="subtitle1">
                    {props.groupTransactionDetails.date}
                </Typography>
            </Box>
            <Box
             display={"flex"}
             flexDirection={"column"}
             sx={{
                gap: 0.5
             }}
            >
                {props.groupTransactionDetails.transactionDetails.map(transactionDetails => (
                    <TransactionItemV2
                        key={transactionDetails.id}
                        id={transactionDetails.id}
                        title={transactionDetails.title}
                        amount={transactionDetails.amount}
                        institution={transactionDetails.institutionName!}
                        account={transactionDetails.accountName}
                        isPending={transactionDetails.isPending === true}
                        isReviewed={transactionDetails.defaultQueue === "Reviewed"}
                        action={transactionDetails.defaultQueue && transformQueueName(transactionDetails.defaultQueue)}
                        reviewButtonCallback={() => {
                            reviewQueue && props.groupQueueDetails.onQueueChange(props.groupTransactionDetails.date, transactionDetails.id, reviewQueue)
                        }}
                        queueDetails={{
                            queues: updatedQueues,
                            onQueueChange: (queue) => {
                                props.groupQueueDetails.onQueueChange(props.groupTransactionDetails.date, transactionDetails.id, reverseMap(queue))
                            }
                        }}
                        onTransactionClick={props.onTransactionClick}
                    />
                ))}
            </Box>
        </Box>
    )
});