import { Container, CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getQueues, Queue } from "../Backend/SplitzUpApp/splitzUpApp";
import { AddQueue } from "../Components/AddQueue";
import { QueuesList } from "../Components/QueuesList";
import { sortQueues } from "../utils/queueUtils";

export default function Queues () {

    const [queueFetchState, setQueueFetchState] = React.useState<string>("Init");
    const [queues, setQueues] = React.useState<Queue[]>([]);
    const navigate = useNavigate();

    const onQueueClick = (queueId: string) => {
        navigate(`/Transactions?queueId=${queueId}`)
    }

    useEffect(() => {
        setQueueFetchState("Fetching");
        getQueues().then(response => {
            const queues = response.queues;
            setQueues(sortQueues(queues));
            setQueueFetchState("Success");
        }).catch(error => {
            setQueueFetchState("Error");
        });
    }, [setQueueFetchState]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <QueuesList fetchState={queueFetchState} queues={queues} onQueueClick={onQueueClick}/>
            <AddQueue/>
        </Container>
    )
}
        
