import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getOnboardingState } from "../Backend/SplitzUpApp/Onboarding";
import { getQueues } from "../Backend/SplitzUpApp/splitzUpApp";
import { ActionContext, ActionData } from "../Context/ActionContext";
import { AuthContext, isUserSessionExpired } from "../Context/AuthContext";
import { LinkTokenContextProvider } from "../Context/LinkTokenContextProvider";
import { transferQueuesToActions } from "../utils/queueUtils";

export const PrivateRoute = (props: any) => {
  
    const {user} = useContext(AuthContext); 
    const {actions, setActions} = useContext(ActionContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (user === null || isUserSessionExpired(user.expiryTime)) {
            navigate("/Sign-in?redirect-url="+encodeURIComponent(window.location.href));
        }        
    }, [user, navigate]);

    useEffect(() => {
        if (actions.length === 0) {
            getQueues().then(response => {
                setActions(transferQueuesToActions(response.queues));
            })
        }
    }, [actions, setActions])

    useEffect(() => {
        if (location.pathname === "/Onboarding") {
            return;
        }
        getOnboardingState().then(response => {
            if (!response.isComplete) {
                navigate("/Onboarding", {
                    state: {
                        nextStep: response.nextStep,
                    },
                });
            }
        });
    }, [navigate, location])

    return (
        <LinkTokenContextProvider>
            {props.children}
        </LinkTokenContextProvider>
    )
  };