import { ForwardedRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { MenuItem } from '../interface';
import { IconActivity } from '@tabler/icons';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

export interface NavItemProps {
  item: MenuItem;
  level: number;
  onClick: () => void;
}

const NavItem = ({ item, level, onClick }: NavItemProps) => {
  const Icon = item.icon ?? IconActivity;
  const itemIcon = 
    <Icon stroke={1.5} size="1.3rem" />

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref: ForwardedRef<HTMLAnchorElement>) => <Link ref={ref} {...props} to={item.url!} target={itemTarget} />)
  };

  const itemHandler = (id: any) => {
    onClick();
  };

  const selected: boolean = "/".concat(window.location.pathname+window.location.search).substring(1) === item.url;
  
  return (
    <ListItemButton
      {...listItemProps}
      sx={{
        borderRadius: `12px`,
        alignItems: 'flex-start',
        // backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        // py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`
      }}
      selected={selected}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>{itemIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography variant={level > 1 ? 'subtitle1' : 'h6'} color="inherit" sx={{fontWeight: (item.size ?? 0) > 0 ? "bold" : undefined}}>
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {(item.size ?? 0) > 0 ? 
        <Typography variant="subtitle1" color="inherit" sx={{fontWeight: "bold"}}>
          {item.size}
        </Typography>
        : undefined
      }
    </ListItemButton>
  );
};

export default NavItem;
