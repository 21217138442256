import { Typography } from "@mui/material";
import React from "react";

export interface PasswordRequirementsProps {
    requirements: string[];    
}

export default function PasswordRequirements({
    requirements
}: PasswordRequirementsProps) {
    return (
        <>
            {requirements.map((requirement, index) => (
                <Typography key={index} variant="body2" color="text.secondary" align="left">
                    {requirement}
                </Typography>
            ))}
        </>
    )
}