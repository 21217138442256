import { createContext } from 'react';

export interface User {
    email: string;
    name: string;
    idToken: string;
    expiryTime: string;
}

/**
 * 
 * @param expiryTime
 * @returns if user session is expired
 */
export const isUserSessionExpired = (expiryTime: string): boolean => {
  try {
    const expiryTimeEpoch = Number(expiryTime);
    const currentTimeEpoch = new Date().getTime();
    const isExpired = currentTimeEpoch > expiryTimeEpoch - 60*1000;
    return isExpired;
  } catch (err) {
    return true;
  }
}

interface IAuthContext {
  user: User | null;
  setUser: (user: User) => void;
  removeUser: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
  removeUser: () => {},
});