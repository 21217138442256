import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CaughtUp = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CheckCircleIcon style={{ fontSize: 64, color: 'green' }} />
      <Typography variant="h6" align="center">
        You're all caught up! 🎉
      </Typography>
    </div>
  );
};

export default CaughtUp;
