import { Box, Button, TextField } from "@mui/material"
import React, { useState } from "react"
import { addQueue } from "../Backend/SplitzUpApp/splitzUpApp";
import { useFormValidator, ValidationFormType } from "../hooks/useFormValidator";
import Toast from "./Toast";

export const AddQueue = () => {
    const { register, handleSubmit, errors, reset } = useFormValidator(ValidationFormType.QUEUE);

    const [errorToastText, setErrorToastText] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);


    const onSubmit = (data: any) => {
        console.log(data);
        addQueue(data.queue)
        .then(() =>{
            reset();
            window.location.reload();
        })
        .catch(
            (err) => {
                console.log(err);
                setErrorToastText(err.message);
                setShowErrorToast(true);
            }
        )
    }
    

    return (
        <>
            <Toast message={errorToastText} open={showErrorToast} handleClose={() => setShowErrorToast(false)} type="error" />
            <Box
                sx={{
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
        >
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} >
                <TextField
                    margin="normal"
                    size="small"
                    id="queue"
                    label="Queue"
                    sx = {{
                        m: 0,
                        width: "50%",
                    }}
                    required
                    {...register('queue')}
                    error={!!errors.queue}
                    helperText={errors.queue?.message?.toString()}
                />
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    sx={{ ml: 2, mt: 0.5 }}
                    size="small"
                >
                    Add queue
                </Button>
            </Box>
        </Box>
      </>
    )
}