import { Box, Container, CssBaseline } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Account, deleteItem, getAccounts } from "../Backend/SplitzUpApp/splitzUpApp";
import { AddAccount } from "../Components/AddAccount";
import { Institution, InstitutionsList } from "../Components/InstitutionList";
import Toast from "../Components/Toast";

export default function Institutions () {

    const [institutionFetchState, setInstitutionFetchState] = React.useState<string>("Init");
    const [institutions, setInstitutions] = React.useState<Institution[]>([]);
    const navigate = useNavigate();

    const [toastText, setToastText] = useState("");
    const [showToast, setShowToast] = useState(false);

    const onAccountClick = (instituionId: string, accountId: string) => {
        navigate(`/Transactions?accountId=${accountId}&itemId=${instituionId}`)
    }

    const onDeleteItemClick = (instituionId: string, name: string) => {
        deleteItem(instituionId).then(() => {
            setToastText(`We are deleting ${name} accounts. It will take few minutes.`);
            setShowToast(true);
            const updatedInstitutions = institutions.filter(institute => institute.id !== instituionId)
            setInstitutions(updatedInstitutions);
        })
    }

    useEffect(() => {
        setInstitutionFetchState("Fetching");
        getAccounts().then(accounts => {
            const instituions = convertAccountToInstitutions(accounts);
            setInstitutions(instituions);
            setInstitutionFetchState("Success");
        }).catch(error => {
            setInstitutionFetchState("Error");
        });
    }, [setInstitutionFetchState]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Toast message={toastText} open={showToast} handleClose={() => setShowToast(false)} type={"info"} />
            <InstitutionsList
                fetchState={institutionFetchState} 
                institutions={institutions} 
                onAccountClick={onAccountClick}
                onDeleteItemClick={onDeleteItemClick}
            />
            <Box alignItems="center" display="flex" justifyContent="center" >
                <AddAccount allow={institutions.length < 2}/>    
            </Box>
        </Container>
    )
}

const convertAccountToInstitutions = (accounts: Account[]): Institution[] => {
    const institionMap = new Map<string, Institution>();
    accounts.forEach(account => {
        const institutionDetails = institionMap.get(account.institution.id);
        if (!institutionDetails) {
            institionMap.set(account.institution.id, {
                id: account.institution.id,
                name: account.institution.name,
                logo: account.institution.logo,
                itemState: account.institution.itemState,
                accounts: [{
                    id: account.id,
                    name: account.name
                }]
            });
        } else {
            institionMap.set(account.institution.id, {
                ...institutionDetails,
                accounts: [...institutionDetails.accounts, {
                    id: account.id,
                    name: account.name
                }]
            });
        }
    });

    const institutions: Institution[] = [];
    institionMap.forEach(institution => {
        if (institution.itemState !== "Deleting") {
            institutions.push(institution);
        }
    });

    return institutions;
}
        
