import { Box, IconButton, Paper, Typography } from "@mui/material";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import QueueSelector from "./QueueSelector";
import { QueueDetails } from "./TransactionItem";
import { Queue } from "../Backend/SplitzUpApp/splitzUpApp";
import { useState } from "react";

export interface TransactionItemV2Props {
    id: string;
    title: string;
    amount: string;
    institution: string;
    isPending: boolean;
    account?: string;
    isReviewed?: boolean;
    action?: string;
    reviewButtonCallback: () => void;
    queueDetails: QueueDetails;
    onTransactionClick: (id: string) => void;
}
export default function TransactionItemV2(props: TransactionItemV2Props) {
    function displayTitle(title: string, threshold: number): string {
        return title.length > threshold ? props.title.slice(0,threshold).concat("..") : props.title
    }

    const [displayCheckIcon, setDisplayCheckIcon] = useState(props.isReviewed !== true);
    
    const queueSelectorArgs = {
        defaultValue: props.isReviewed ? undefined : props.action,
        queues: props.queueDetails.queues,
        onChange: (queue: Queue) => {
            if (displayCheckIcon === false) {
                setDisplayCheckIcon(true);
            }
            props.queueDetails.onQueueChange(queue)
        }
    };

    const secondaryValue = props.account ? `${props.institution} - ${props.account}` : props.institution

    return (
        <Paper
            elevation={props.isReviewed ? 0 : 2}
            sx={{
                borderRadius: 2,
                backgroundColor: props.isReviewed ? "#EEEEEE" : undefined,
            }}
            onClick={() => props.onTransactionClick(props.id)}
        >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, paddingX: 1, paddingY: 0.5 }}>
            <Box sx={{width: "50px"}}>
                <Typography 
                    variant={
                        props.amount.length > 7 
                        ? "body2" 
                        : props.amount.length > 4
                        ? "subtitle2"
                        : "subtitle1"
                    }
                    color={props.isReviewed ? undefined : "primary.main"}
                >
                    {props.amount}
                </Typography>
            </Box>
            <Box>
                <Typography variant="body2" sx={{fontWeight: props.isReviewed ? undefined : "bold"}}>
                    {displayTitle(props.title, props.isReviewed ? 23 : 17)}
                </Typography>
                <Typography variant="body2">
                    {secondaryValue}
                </Typography>
                {props.isPending && (
                    <Typography variant="body2">
                        {"Pending transaction"}
                    </Typography>
                )}
            </Box>
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                    }}
                >
                    {!props.isReviewed && (
                        <IconButton onClick={(event: any) => {props.reviewButtonCallback(); event.stopPropagation();}}>
                            <CheckCircleOutlinedIcon color="action"/>
                        </IconButton>
                    )}
                    <QueueSelector {...queueSelectorArgs}/>
                </Box>
        </Box>
        </Paper>
    )
}