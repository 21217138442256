import { useState } from "react";

enum RequirementType {
    LENGTH = "length",
    HAS_UPPERCASE = "hasUpperCase",
    HAS_LOWERCASE = "hasLowerCase",
    HAS_DIGIT = "hasDigit",
    HAS_SPECIAL_CHAR = "hasSpecialChar",
}

interface PasswordRequirement {
    requirement: RequirementType;
    requirementDisplayString: string;
    requirementMet: boolean;
}

const defaultPasswordRequirement: PasswordRequirement[] = [
    // {
    //   requirement: RequirementType.HAS_UPPERCASE,
    //   requirementDisplayString: "- one uppercase letter",
    //   requirementMet: false,
    // },
    // {
    //   requirement: RequirementType.HAS_LOWERCASE,
    //   requirementDisplayString: "- one lowercase letter",
    //   requirementMet: false,
    // },
    // {
    //   requirement: RequirementType.HAS_DIGIT,
    //   requirementDisplayString: "- one number",
    //   requirementMet: false,
    // },
    // {
    //   requirement: RequirementType.HAS_SPECIAL_CHAR,
    //   requirementDisplayString: "- one special character",
    //   requirementMet: false,
    // },
    {
      requirement: RequirementType.LENGTH,
      requirementDisplayString: "8 characters",
      requirementMet: false,
    },
  ];

const updatePasswordRequirement = (passwordRequirements: PasswordRequirement[], requirement: RequirementType, requirementMet: boolean) => {
    const passwordRequirement = passwordRequirements.find((r) => r.requirement === requirement);
    if (passwordRequirement) {
      passwordRequirement.requirementMet = requirementMet;
    }
}

const updatePasswordRequirements = (password: string, currentPasswordRequirements: PasswordRequirement[]): PasswordRequirement[] => {
    const updatedPasswordRequirements = currentPasswordRequirements;
    updatePasswordRequirement(updatedPasswordRequirements, RequirementType.LENGTH, password.length >= 8);
    // updatePasswordRequirement(updatedPasswordRequirements, RequirementType.HAS_UPPERCASE, /[A-Z]/.test(password));
    // updatePasswordRequirement(updatedPasswordRequirements, RequirementType.HAS_LOWERCASE, /[a-z]/.test(password));
    // updatePasswordRequirement(updatedPasswordRequirements, RequirementType.HAS_DIGIT, /\d/.test(password));
    // updatePasswordRequirement(updatedPasswordRequirements, RequirementType.HAS_SPECIAL_CHAR, /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password));
    return updatedPasswordRequirements;
}

export const usePasswordRequirements = () => {
  const [passwordRequirements, setPasswordRequirements] = useState(defaultPasswordRequirement);
  const [password, setPasswordValue] = useState("");

  const setPassword = (passwordValue: string) => {
    setPasswordValue(passwordValue);
    const updatedPasswordRequirements = updatePasswordRequirements(passwordValue, passwordRequirements);
    setPasswordRequirements(updatedPasswordRequirements);
  }

  const unsatisfiedRequirementStrings: string[] = passwordRequirements.filter((r) => !r.requirementMet).map((r) => r.requirementDisplayString);
  // const passwordRequirementsStrings: string[] = unsatisfiedRequirementStrings.length > 0 ? 
  //   ["Password must have at least:"].concat(unsatisfiedRequirementStrings) :
  //   [];

  const passwordRequirementsStrings: string[] = unsatisfiedRequirementStrings.length > 0 
    ? ["Password must have at least " + unsatisfiedRequirementStrings] 
    : [];

  return {password, setPassword, passwordRequirementsStrings}
}