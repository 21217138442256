// material-ui
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { MenuItem } from '../interface';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export const menuCaption = {
  fontSize: '0.875rem',
  fontWeight: 500,
  padding: '2px',
  textTransform: 'capitalize',
  marginTop: '5px'
};

export const subMenuCaption = {
  fontSize: '0.6875rem',
  fontWeight: 500,
  textTransform: 'capitalize'
};

export interface NavGroupProps {
  item: MenuItem;
  onItemClick: () => void;
}

const NavGroup = ({ item, onItemClick }: NavGroupProps) => {

  // menu list collapse & items
  const items = item.children?.map((menu: MenuItem) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} onItemClick={onItemClick} />;
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} onClick={onItemClick}/>;
      default:
        return (
          <Typography key={menu.id} variant="h5" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...menuCaption }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

export default NavGroup;
