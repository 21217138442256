import { IconInbox, IconActivity, IconEye, IconSettings, IconPower, IconBuildingBank } from '@tabler/icons';
import { MenuItem } from '../Components/MenuList/interface';
import { ActionData } from '../Context/ActionContext';

export const buildMenuItems = (actions: ActionData[]): MenuItem[] => {
  return [
    {
      id: 'inbox',
      title: 'Inbox',
      type: 'group',
      children: [
        {
          id: 'inbox',
          title: 'Inbox',
          type: 'item',
          icon: IconInbox,
          url: '/Inbox'
        },
        {
          id: 'actions',
          title: 'Actions',
          type: 'collapse',
          icon: IconActivity,
          children: actions.map((action: ActionData) => {
            return {
              id: action.id,
              title: action.displayValue,
              type: 'item',
              icon: IconEye,
              url: '/Inbox?queueId=' + action.id,
              size: action.quantity
            }
          })
        },
      ]
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'group',
      children: [
        {
          id: 'settings',
          title: 'Settings',
          type: 'collapse',
          icon: IconSettings,
          children: [
            {
              id: 'accounts',
              title: 'Accounts',
              type: 'item',
              icon: IconBuildingBank,
              url: '/Settings/Accounts'
            },
            {
              id: 'actions',
              title: 'Actions',
              type: 'item',
              icon: IconActivity,
              url: '/Settings/Actions'
            }
          ]
        },
        {
          id: 'logout',
          title: 'Logout',
          type: 'item',
          icon: IconPower,
          url: '/Logout'
        }
      ]
    }
  ];
}