import { Box, Button, Card, CardHeader, Container, Divider, Grid, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import React, { useContext } from "react";
import { getQueues } from "../Backend/SplitzUpApp/splitzUpApp";
import { ActionContext, ActionData } from "../Context/ActionContext";
import { transferQueuesToActions } from "../utils/queueUtils";
import { AddActionForm } from "./AddActionForm";

export default function AddActions(props: {
    onComplete?: () => void;
    showNext?: boolean
}) {
    const renderActionNames = (actions: ActionData[]) => (
        actions.map((action: ActionData, index) => {
          return (
            <React.Fragment key={action.id}>
              <ListItemButton 
                key={action.id}
                alignItems="flex-start" 
                sx={{ p: 0 }}
            >
                <ListItemText
                  primary={action.displayValue}
                  primaryTypographyProps={{ variant: 'subtitle1', align: 'center' }}
                />
              </ListItemButton>
              {index < actions.length - 1 && <Divider />}
            </React.Fragment>
          );
        })
      );

    const {actions, setActions} = useContext(ActionContext);

    const onAddAction = () => {
        getQueues().then(response => {
            setActions(transferQueuesToActions(response.queues));
        })
    }

    return (
        <Box>
            <Container maxWidth="sm">
                <Typography
                    component="h2"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Add Actions
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                    Add custom actions that you can take on your transactions. By default we provide "Review" and "Split" actions. You can add more actions like "Reimburse", "Dispute".
                </Typography>
                <Grid container justifyContent={"center"} spacing={2} marginTop={2}>
                    <Grid item width="80%">
                        <Card sx={{
                            alignItems: "center",
                            borderRadius: 3
                        }}>
                            <CardHeader title="Actions" align="center" />
                            <List sx={{ width: '100%', bgcolor: 'white', color: 'black'}}>
                                {renderActionNames(actions)}
                            </List>
                        </Card>                        
                    </Grid>
                    <Grid item width="80%">
                        <AddActionForm onSuccessCallback={onAddAction}/>
                    </Grid>
                    <Grid item width="80%" alignItems={"center"}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}    
                        >
                            {props.showNext && <Button variant="outlined" onClick={props.onComplete}>Next</Button>}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
