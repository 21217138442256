import { createContext } from 'react';

export interface ActionData {
    id: string;
    displayValue: string;
    quantity?: number;
}

interface IActionContext {
  actions: ActionData[]
  setActions: (actions: ActionData[]) => void;
}

export const ActionContext = createContext<IActionContext>({
    actions: [],
    setActions: () => {}  // eslint-disable-line @typescript-eslint/no-empty-function
});