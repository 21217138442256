import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";

export const useAddAccount = (
    onSuccess: (token: string) => void,
    onExit: (err: any, metadata: any) => void
) => {
    const [linkToken, setLinkToken] = useState();
    
    const config: Parameters<typeof usePlaidLink>[0] = {
        token: linkToken!,
        onSuccess,
        onExit
    };

    const { open, ready } = usePlaidLink(config);

    return { open, ready, setLinkToken };
}
