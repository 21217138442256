import { PATHS } from "./splitzUpApp";

const ENDPOINT = 'https://ln0nebfon7.execute-api.us-west-2.amazonaws.com/prod';

const getIdToken = () => {
    const idToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).idToken : undefined;
    return idToken;
}

export const sendRequest = async (path: PATHS, urlSearchParameters: URLSearchParams | undefined, method: string, headers: any, body: string | null) => {
    const url = new URL(`${ENDPOINT}${path}`);
    if (urlSearchParameters) {
        url.search = urlSearchParameters.toString();
    }
    printRequest(url.href, method, headers, body);
    const idToken = getIdToken();
    if (!idToken) {
        throw new Error('No idToken');
    }
    const response = await fetch(url, {
        method,
        headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type' : 'application/json',
            ...headers,
        },
        body
    }).then(async (response) => {
        if (!response.ok) {
            const error = await response.json();
            return Promise.reject(error);
        } else {
            return response;
        }
    });
    return response.json();
}

const printRequest = (url: string, method: string, headers: any, body: string | null) => {
    console.log(`url: ${url}`);
    console.log(`method: ${method}`);
    console.log(`headers: ${JSON.stringify(headers)}`);
    console.log(`body: ${body}`);
}

