import { PATHS } from "./splitzUpApp";
import { sendRequest } from "./splitzUpApp-client";

export interface GetOnboardingStateResponse {
    isComplete: boolean;
    nextStep: string;
}

export interface SetOnboardingStateRequest {
    completedStep: string;
}

export interface SetOnboardingStateResponse {
    isComplete: boolean;
    nextStep: string;
}

export const getOnboardingState = async (): Promise<GetOnboardingStateResponse> => {
    try {
        return await sendRequest(PATHS.ONBOARDING, undefined, 'GET', null, null)
        // return {
        //     isComplete: false,
        //     nextStep: "AddAccount"
        // }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const setOnboardingState = async (request: SetOnboardingStateRequest): Promise<SetOnboardingStateResponse> => {
    try {
        return await sendRequest(PATHS.ONBOARDING, undefined, 'PATCH', null, JSON.stringify(request))
    } catch (err) {
        console.log(err);
        throw err;
    }
}