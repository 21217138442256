import { Box, Container, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

export default function Logout() {
    const navigate = useNavigate();
    const { removeUser } = useContext(AuthContext);
    useEffect(() => {
        localStorage.removeItem('user');
        removeUser();
        setTimeout(() => {
            navigate('/Sign-in');
        }, 2000);
    }, [])
    return (
        <Box>
            <Container maxWidth="sm">
                <Typography variant="h4" align="center">
                    Logging out...
                </Typography>
            </Container>
        </Box>
    )
}