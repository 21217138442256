import { User } from "../../Context/AuthContext";
import { sendRequest } from "./splitzUpApp-client";

export const enum PATHS {
    LINK_TOKEN = '/LinkToken',
    ACCESS_TOKEN = '/AccessToken',
    ITEM = '/Item',
    ACCOUNTS = '/Accounts',
    TRANSACTIONS = '/Transactions',
    TRANSACTION = '/Transaction',
    QUEUES = '/Queues',
    QUEUE = '/Queue',
    ONBOARDING = "/Onboarding"
}

export const getLinkToken = async (itemId?: string) => {
    try {
        const body = {
            itemId
        }
        const linkTokenResponse = await sendRequest(PATHS.LINK_TOKEN, undefined, 'POST', null, JSON.stringify(body));
        return linkTokenResponse.linkToken;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
    
export const setAccessToken = async (publicToken: string): Promise<string> => {
    try {
        const body = {
            publicToken
        }
        const setAccessTokenResponse = await sendRequest(PATHS.ACCESS_TOKEN, undefined, 'POST', null, JSON.stringify(body));
        return setAccessTokenResponse.itemId as string;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const updateItemState = async (itemId: string, itemState: string) => {
    try {
        const body = {
            itemId,
            itemState
        }
        const updateItemStateResponse = await sendRequest(PATHS.ITEM, undefined, 'PATCH', null, JSON.stringify(body));
        return updateItemStateResponse;
    } catch (err) {
        console.log(err);
        throw(err);
    }
}

export const deleteItem = async (itemId: string) => {
    try {
        const body = {
            itemId
        }
        await sendRequest(PATHS.ITEM, undefined, 'DELETE', null, JSON.stringify(body));
    } catch (err) {
        console.log(err);
        throw(err);
    }
}

export interface Institution {
    id: string;
    name: string;
    logo?: string;
    itemState?: string;
}

export interface Account {
    id: string;
    name: string;
    type: string;
    balances?: string;
    institution: Institution
}

export const getAccounts = async (): Promise<Account[]> => {
    try {
        const accounts = await sendRequest(PATHS.ACCOUNTS, undefined, 'GET', null, null);
        return accounts.data as Account[];
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export interface GetTransactionsParams {
    queueId?: string;
    accountId?: string;
    itemId?: string;
    exclusiveStartTransaction?: string;
}

export interface Location {
    address?: string;
    city?: string;
    postal_code?: string; //todo: change it to camelCase
}

export interface Transaction {
    transactionId: string;
    transactionTitle: string;
    transactionTimestamp: string;
    transactionDate: string;
    transactionAmount: string;
    transactionAccount: string;
    transactionAccountType: string;
    transactionInstitutionName?: string;
    transactionQueue: string;
    transactionAuthorizedDate?: string;
    transactionPostedDate?: string;
    transactionLocation?: Location;
    transactionIsPending?: boolean;
    transactionAuthorizedDateTime?: string;
    transactionMerchantName?: string;
    transactionPaymentChannel?: string;
}

export interface GetTransactionsResponse {
    transactions: Transaction[];
    exclusiveStartTransaction?: string;
    inProgress: string[];
}

export interface CreateUserResponse {
    user: User
}

export const getTransactions = async ({
    queueId,
    accountId,
    itemId,
    exclusiveStartTransaction
}: GetTransactionsParams): Promise<GetTransactionsResponse> => {
    try {
        const urlSearchParameters: URLSearchParams = new URLSearchParams({
            ...(queueId && { queueId }),
            ...(accountId && { accountId }),
            ...(itemId && { itemId }),
            ...(exclusiveStartTransaction && { exclusiveStartTransaction }),
        });
        console.log(urlSearchParameters);
        const response = await sendRequest(PATHS.TRANSACTIONS, urlSearchParameters, 'GET', null, null);
        return {
            transactions: response.data as Transaction[],
            exclusiveStartTransaction: response.exclusiveStartKey as string,
            inProgress: response.inProgress as string[]
        }
        // return getTransactionsResponse;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export interface Queue {
    id: string;
    name: string;
}

export interface GetQueuesResponse {
    queues: Queue[];
}

export const getQueues = async (): Promise<GetQueuesResponse> => {
    try {
        const getQueuesResponse = await sendRequest(PATHS.QUEUES, undefined, 'GET', null, null);
        return {
            queues: getQueuesResponse.data as Queue[]
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const addQueue = async (queueName: string): Promise<string> => {
    try {
        const body = {
            queueName
        }
        const addQueueResponse = await sendRequest(PATHS.QUEUE, undefined, 'POST', null, JSON.stringify(body));
        return addQueueResponse.queueId;
    } catch (err: any) {
        console.log(err);
        throw new Error(err.error);
    }
}

export const updateTransaction = async (transactionId: string, queueId: string): Promise<void> => {
    try {
        const body = {
            transactionId,
            queueId
        }
        await sendRequest(PATHS.TRANSACTION, undefined, 'PATCH', null, JSON.stringify(body));
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const createUser = async (jwt: string): Promise<CreateUserResponse> => {
    return {
        user: {
            idToken: jwt,
            name: "Smit Thakkar",
            email: "sthakkar1407@gmail.com",
            expiryTime: (Date.now() + 100000000).toString()
        }
    }
}