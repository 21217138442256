import { useEffect, useState } from "react"
import { getQueues } from "../Backend/SplitzUpApp/splitzUpApp"
import { ActionData } from "../Context/ActionContext"
import { transferQueuesToActions } from "../utils/queueUtils"

export const useActions = () => {
    const [actions, setActions] = useState<ActionData[]>([])

    useEffect(() => {
        getQueues().then(response => {
            setActions(transferQueuesToActions(response.queues));
        })
    }, []);
    
    return {
        actions, setActions
    }
}