import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, IAuthenticationCallback } from "amazon-cognito-identity-js";
import { CodeMismatchException, ExpiredCodeError, ExpiredCodeException, InvalidCodeError, InvalidParameterError, InvalidParameterException, LimitExceededError, LimitExceededException, NotAuthorizedError, NotAuthorizedException, ServerError, TooManyFailedAttemptsError, TooManyFailedAttemptsException, UserAlreadyExistsError, UserAlreadyExistsException, UserNotConfirmedError, UserNotConfirmedException, UserNotFoundError, UserNotFoundException } from "../Error/cognito";

const poolData = {
  UserPoolId: "us-west-2_GuqkJ7wHZ",
  ClientId: "8j17n0fmbo45ffvm5ubo6titn",
};

export const userPool = new CognitoUserPool(poolData);

export const signUp = async (email: string, password: string, firstName: string, lastName: string, onSuccess: () => void, onError: (err: Error) => void): Promise<void> => {
    const dataEmail = {
        Name: "email",
        Value: email,
      };
  
      const dataName = {
        Name: "name",
        Value: `${firstName} ${lastName}`,
      };

    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeName = new CognitoUserAttribute(dataName);
    const attributeList = [attributeEmail, attributeName];

    const callBack = (err: any) => {
      if (err) {
        ErrorCallBack(onError)(err);
      } else {
        onSuccess();
      }
    }

    userPool.signUp(email, password, attributeList, [], callBack);
}

export const confirmRegistration = (email: string, code: string, onSuccess: () => void, onError: (err: Error) => void): void => {
  const cognitoUser = createCognitoUser(email);

  const callBack = (err: any) => {
    if (err) {
      ErrorCallBack(onError)(err);
    } else {
      onSuccess();
    }
  }

  cognitoUser.confirmRegistration(code, true, callBack);
}

export const resendConfirmationCode = (email: string, onSuccess: () => void, onError: (err: Error) => void): void => {
  const cognitoUser = createCognitoUser(email);

  const callBack = (err: any) => {
    if (err) {
      ErrorCallBack(onError)(err);
    } else {
      onSuccess();
    }
  }

  cognitoUser.resendConfirmationCode(callBack);
}

export const authenticateUser = (
  email: string, 
  password: string, 
  onSuccess: IAuthenticationCallback["onSuccess"], 
  onError: (err: Error) => void,
): void => {
  const cognitoUser = createCognitoUser(email);
  cognitoUser.authenticateUser(new AuthenticationDetails({
      Username: email,
      Password: password,
  }), {
      onSuccess: onSuccess,
      onFailure: ErrorCallBack(onError),
  });
}

export const forgotPassword = (email: string, onSuccess: (data: any) => void, onError: (err: Error) => void): void => {
    const cognitoUser = createCognitoUser(email);
    cognitoUser.forgotPassword({
        onSuccess: onSuccess,
        onFailure: ErrorCallBack(onError),
    });
}

export const confirmForgotPassword = (email: string, code: string, password: string, onSuccess: (data: any) => void, onError: (err: any) => void): void => {
    const cognitoUser = createCognitoUser(email);
    cognitoUser.confirmPassword(code, password, {
        onSuccess: onSuccess,
        onFailure: ErrorCallBack(onError),
    });
}

const createCognitoUser = (email: string): CognitoUser => {
  return new CognitoUser({
      Username: email,
      Pool: userPool,
  });
}

const ErrorCallBack = (upstreamCallBack: (err: Error) => void) => {
  return (err: any) => {
    console.log(err.code);
    console.log(err.message);
    let translatedError: Error;
    if (err.code === UserAlreadyExistsException) {
      translatedError = new UserAlreadyExistsError(err.message);
    } else if (err.code === CodeMismatchException) {
      translatedError = new InvalidCodeError(err.message);
    } else if (err.code === ExpiredCodeException) {
      translatedError = new ExpiredCodeError(err.message);
    } else if (err.code === TooManyFailedAttemptsException) {
      translatedError = new TooManyFailedAttemptsError(err.message);
    } else if (err.code === UserNotConfirmedException) {
      translatedError = new UserNotConfirmedError(err.message);
    } else if (err.code === UserNotFoundException) {
      translatedError = new UserNotFoundError(err.message);
    } else if (err.code === NotAuthorizedException) {
      translatedError = new NotAuthorizedError(err.message);
    } else if (err.code === InvalidParameterException) {
      translatedError = new InvalidParameterError(err.message);
    } else if (err.code === LimitExceededException) {
      translatedError = new LimitExceededError(err.message);
    } else {
      translatedError = new ServerError(err.message);
    }
    
    upstreamCallBack(translatedError);
  }
}