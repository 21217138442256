import { Button, Tooltip } from "@mui/material"
import React, { useEffect } from "react";
import { getLinkToken, setAccessToken } from "../Backend/SplitzUpApp/splitzUpApp";
import { useAddAccount } from "../hooks/useAddAccount";

export interface AddAccountProps {
    allow: boolean;
    onStartCallback?: () => void;
    onSuccessCallback?: () => void;
}

export const AddAccount = (props: AddAccountProps) => {
    console.log(props.allow);
    const onSuccess = (token: string) => {
        if (props.onStartCallback) {
            props.onStartCallback();
        }
        setAccessToken(token).then(() => {
            if (props.onSuccessCallback) {
                props.onSuccessCallback();
            }
        }).catch(err => {
            // TODO: handle error message.
            alert("Error adding account. Please try again later.");
            console.error(err);
        });
    }

    const onExit = (error: any, metadata: any) => {
        if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
            getLinkToken().then(token => {
                console.log('set token');
                setLinkToken(token);
            }).catch(err => {
                console.error(err);
            });
        }
    }

    const {open, ready, setLinkToken} = useAddAccount(onSuccess, onExit);

    useEffect(() => {
        getLinkToken().then(token => {
            setLinkToken(token);
        }).catch(err => {
            console.error(err);
        });
    }, [setLinkToken]);
    
    return (
        <Tooltip 
            title={(!ready || !props.allow) ? "Contact splitzup.net+support@gmail.com to add account" : ""}
            placement="top"
        >
            <div style={{ display: 'inline-block' }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => open()}
                    disabled={!ready || !props.allow}
                >
                    Add account
                </Button>
            </div>
        </Tooltip>
    )
}