import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMemo } from 'react';

export enum ValidationFormType {
    SIGN_UP,
    SIGN_IN,
    VERIFICATION_CODE,
    FORGOT_PASSWORD_BEFORE_SEND,
    FORGOT_PASSWORD_AFTER_SEND,
    QUEUE
}

type Fields = "firstName" | "lastName" | "email" | "code" | "password" | "queue";

const formTypeToFieldsMap = new Map<ValidationFormType, Fields[]>([
    [ValidationFormType.SIGN_UP, ["email"]],
    [ValidationFormType.SIGN_IN, ["email", "password"]],
    [ValidationFormType.VERIFICATION_CODE, ["code",]],
    [ValidationFormType.FORGOT_PASSWORD_BEFORE_SEND, ["email"]],
    [ValidationFormType.FORGOT_PASSWORD_AFTER_SEND, ["code"]],
    [ValidationFormType.QUEUE, ["queue"]],
]);

const fieldToYupObjectMap = new Map<Fields, any>([
    ["firstName", Yup.string().required('First name is required')],
    ["lastName", Yup.string().required('Last name is required')],
    [
        "email",  
        Yup.string().required('Email is required').email('Email is invalid')
    ],
    ["code", Yup.string().required('Code is required')],
    ["password", Yup.string().required('Password is required')],
    ["queue", Yup.string().required('Name is required')],
])

const getValidationSchema = (formType: ValidationFormType) => {
    const fields = formTypeToFieldsMap.get(formType);
    const yupObjects: {[key: string]: any} = {};
    fields?.forEach((field) => {
        yupObjects[field] = fieldToYupObjectMap.get(field);
    });
    return Yup.object().shape(yupObjects);
}

export const useFormValidator = (formType: ValidationFormType) => {
    let {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(getValidationSchema(formType))
    });

    return {register, handleSubmit, errors, reset}
}