import { Theme } from "@mui/material";

export default function componentStyleOverrides() {
  return {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '12px'
        },
        title: {
          fontSize: '1.125rem'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '10px 16px 10px 16px',
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '5px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    }
  };
}
