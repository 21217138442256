import { createContext } from "react";

interface ILinkTokenContext {
    linkToken: string;
    setLinkToken: (linkToken: string) => void;
    linkTokenError: string;
    setLinkTokenError: (linkTokenError: string) => void;
}

export const LinkTokenContext = createContext<ILinkTokenContext>({
    linkToken: "",
    setLinkToken: () => {},
    linkTokenError: "",
    setLinkTokenError: () => {},
});