import { useState } from "react";
import { User } from "../Context/AuthContext";

export const useUser = () => {
    const localStorageUser = localStorage.getItem('user');
    const localStorageUserJson = localStorageUser ? JSON.parse(localStorageUser) : null;
    const [ userData, setUserData ] = useState<User | null>(localStorageUserJson);

    const setUser = (user: User) => {
        setUserData(user);
        localStorage.setItem('user', JSON.stringify(user));
    }

    const removeUser = () => {
        setUserData(null);
        localStorage.removeItem('user');
    }

    return { user: userData, setUser, removeUser };
}