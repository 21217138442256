import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticateUser, confirmRegistration, resendConfirmationCode } from "../Backend/cognito";
import { useFormValidator, ValidationFormType } from "../hooks/useFormValidator";
import Toast from "./Toast";
import { ExpiredCodeError, InvalidCodeError, TooManyFailedAttemptsError } from "../Error/cognito";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthContext, User } from "../Context/AuthContext";

export default function Verification() {
  const navigate = useNavigate();
  const state = useLocation().state;
  const email = state?.email;
  const password = state?.password;
  const {register, handleSubmit, errors} = useFormValidator(ValidationFormType.VERIFICATION_CODE);
  const { setUser } = useContext(AuthContext);

  const [toastText, setToastText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState<"error" | "success">("error");

  useEffect(() => {
    if (!email) {
      navigate("/Sign-up");
    }
  }, [email, navigate]);

  const onConfirmRegistrationSuccess = () => {
    authenticateUser(email, password, onSignInSuccess, ErrorCallBack(email));
  }

  const onSignInSuccess = (session: CognitoUserSession) => {
    const user: User = {
        idToken: session.getIdToken().getJwtToken(),
        name: session.getIdToken().payload.name,
        email: session.getIdToken().payload.email,
        expiryTime: (session.getIdToken().payload.exp*1000).toString()
      }
      setUser(user);
      navigate("/Inbox");
  }

  const ErrorCallBack = (email: string) => {
    return (err: Error) => {
      navigate("Sign-in");
    }
  }

  const onConfirmRegistrationError = (err: Error) => {
    if (err instanceof InvalidCodeError) {
      setToastText("Invalid code, please try again!");
      setToastType("error");
      setShowToast(true);
    } else if (err instanceof ExpiredCodeError) {
      setToastText("code is expired, please send the code again!");
      setToastType("error");
      setShowToast(true);
    } else if (err instanceof TooManyFailedAttemptsError) {
      setToastText("too many attempts! please try after sometime.");
      setToastType("error");
      setShowToast(true);
    } else {
      setToastText("Server Error");
      setToastType("error")
      setShowToast(true);
    }
  }

  const onResendConfirmationCodeSuccess = () => {
    setToastText("Code sent!");
    setToastType("success");
    setShowToast(true);
  }

  const onResendConfirmationCodeError = (err: Error) => {
    setToastText("Service Error");
    setToastType("error");
    setShowToast(true);
  }
  
  const onSubmit = (data: any) => {
    confirmRegistration(email, data.code, onConfirmRegistrationSuccess, onConfirmRegistrationError);
  }

  const onClickResend = () => {
    resendConfirmationCode(email, onResendConfirmationCodeSuccess, onResendConfirmationCodeError);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Toast message={toastText} open={showToast} handleClose={() => setShowToast(false)} type={toastType} />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Email verification
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="verification code"
                        label="Code"
                        autoComplete="code"
                        {...register('code')}
                        error={errors.code ? true : false}
                        helperText={errors.code?.message?.toString()}
                    />
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Submit
            </Button>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Link 
                        onClick={onClickResend}
                        variant="body2"
                        sx = {{cursor: "pointer"}}
                    >
                        Didn't receive the code? Resend
                    </Link>
                </Grid>
            </Grid>
        </Box>
      </Box>
    </Container>
  );
}