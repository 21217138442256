import * as React from "react";
import { useLinkToken } from "../hooks/useLinkToken";
import { LinkTokenContext } from "./LinkTokenContext";

export const LinkTokenContextProvider = (props: any) => {
    const {linkToken, setLinkToken, linkTokenError, setLinkTokenError} = useLinkToken();
    return (
        <LinkTokenContext.Provider value={{ linkToken, setLinkToken, linkTokenError, setLinkTokenError }}>
            {props.children}
        </LinkTokenContext.Provider>
    )
}