export const UserAlreadyExistsException = "UsernameExistsException";
export const CodeMismatchException = "CodeMismatchException";
export const ExpiredCodeException = "ExpiredCodeException";
export const TooManyFailedAttemptsException = "TooManyFailedAttemptsException";
export const UserNotConfirmedException = "UserNotConfirmedException";
export const UserNotFoundException = "UserNotFoundException";
export const NotAuthorizedException = "NotAuthorizedException";
export const InvalidParameterException = "InvalidParameterException";
export const LimitExceededException = "LimitExceededException";

export class UserAlreadyExistsError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class InvalidCodeError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class ExpiredCodeError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class TooManyFailedAttemptsError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class UserNotConfirmedError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class UserNotFoundError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class NotAuthorizedError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class InvalidParameterError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class LimitExceededError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class ServerError extends Error {
    constructor(message: string) {
        super(message);
    }
}



