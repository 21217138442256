import {
    Box,
    Card,
    CardHeader,
    Collapse,
    Container,
    CssBaseline,
    Divider,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText} from '@mui/material';
  import React, { useState } from 'react';
  import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { UpdateAccount } from './UpdateAccount';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
  
  export interface Account {
    id: string;
    name: string;
  }

  export interface Institution {
    id: string;
    name: string;
    logo?: string;
    itemState?: string;
    accounts: Account[];
  }
  
  export interface InstitutionsProps {
    fetchState: string;
    institutions: Institution[];
    onAccountClick: (itemId: string, accountId: string) => void;
    onDeleteItemClick: (itemId: string, name: string) => void;
  }
  
  export const InstitutionsList = (props: InstitutionsProps): JSX.Element => {
    const { institutions, onAccountClick, onDeleteItemClick } = props;

    const length = institutions.length;
    const initialCollapseState = Array(length).fill(false);

    const [collapsed, setCollapsed] = React.useState(initialCollapseState);
    const [edit, setEdit] = useState(false);
  
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Card sx={{
            marginTop: 8,
            alignItems: "center",
            borderRadius: 3
          }}>
            <CardHeader title="Accounts" align="center"/>
            <List sx={{ width: '100%', bgcolor: 'white', color: 'black'}}>
            {institutions.map((item: Institution, index) => {
              return (
                <React.Fragment key={item.id}>
                  <ListItemButton 
                    divider={collapsed[index] || index < institutions.length - 1} 
                    key={item.id}
                    alignItems="flex-start" 
                    onClick={() => {collapsed[index] = !collapsed[index]; setCollapsed([...collapsed])}}
                >
                  <ListItemAvatar sx={{mt: '4px', mb: '4px', pl: '8px'}}>
                        {
                        item.logo
                            ? (
                                <Box
                                    component="img"
                                    src={`data:image/png;base64,${item.logo}`}
                                    sx={{
                                        borderRadius: 1,
                                        height: 30,
                                        width: 30
                                    }}
                                />
                            )
                            : (
                                <Box
                                    sx={{
                                    borderRadius: 1,
                                    height: 24,
                                    width: 24
                                    }}
                                >
                                    <AccountBalanceIcon />
                            </Box>
                            )
                        }
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    {item.itemState === "LoginRequired" && !edit && (
                      <UpdateAccount itemId={item.id} />
                    )}
                    {edit && (
                      <IconButton onClick={(event) => {event.stopPropagation(); onDeleteItemClick(item.id, item.name) }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItemButton>
                  <Collapse in={collapsed[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.accounts.map((account: Account, accountIndex) => (
                        <>
                          <ListItemButton 
                            divider={accountIndex === item.accounts.length - 1}
                            key={account.id}
                            sx={{ pl: 10 }}
                            alignItems="flex-start"
                            onClick={() => onAccountClick(item.id, account.id)}
                          >
                            <ListItemText primary={account.name} primaryTypographyProps={{ variant: 'subtitle1' }}/>
                          </ListItemButton>
                          {accountIndex < item.accounts.length - 1 && <Divider variant="inset" component="li" />}
                        </>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            })}
          </List>
        </Card>
      </Container>
    );
  };