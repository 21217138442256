import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { confirmForgotPassword, forgotPassword, resendConfirmationCode } from "../Backend/cognito";
import { usePasswordRequirements } from "../hooks/usePasswordRequirements";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Grid } from "@mui/material";
import PasswordRequirements from "./PasswordRequirements";
import { useFormValidator, ValidationFormType } from "../hooks/useFormValidator";
import Toast from "./Toast";
import { InvalidCodeError, InvalidParameterError, LimitExceededError, UserNotFoundError } from "../Error/cognito";

export default function ForgotPassword() {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const {register: registerBefore, handleSubmit: handleSubmitBefore, errors: errorsBefore} = useFormValidator(ValidationFormType.FORGOT_PASSWORD_BEFORE_SEND);
  const {register: registerAfter, handleSubmit: handleSubmitAfter, errors: errorsAfter} = useFormValidator(ValidationFormType.FORGOT_PASSWORD_AFTER_SEND);
  const [showPassword, setShowPassword] = useState(false);
  const {password, setPassword, passwordRequirementsStrings} = usePasswordRequirements();
  const [showError, setShowError] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [toastText, setToastText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState<"error" | "success">("error");

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  
  const onSuccess = (data: any) => {
    if (emailSent) {
        navigate("/Sign-in");
    }
    setToastText("Code sent!");
    setToastType("success");
    setShowToast(true);
    setEmailSent(true);
  }

  const ErrorCallBack = (email: string) => {
    return (err: Error) => {
      if (err instanceof LimitExceededError) {
        setToastText("Limit exceeded! Please try again after sometime");
        setToastType("error");
        setShowToast(true);
      } else if (err instanceof UserNotFoundError) {
        setToastText("User with this email address doesn't exist");
        setToastType("error");
        setShowToast(true);
      } else if (err instanceof InvalidParameterError) {
        setToastText("Please verify your email first");
        setToastType("error");
        setShowToast(true);
        resendConfirmationCode(email, () => {}, () => {});
        setTimeout(() => {
          navigate("/Verification", {
            state: {
              email,
            },
          });
        }, 2000);
      } else if (err instanceof InvalidCodeError) {
        setToastText("Invalid code, please try again!");
        setToastType("error");
        setShowToast(true);
      } else {
        setToastText("Server Error!");
        setToastType("error");
        setShowToast(true);
      }
    }
  }

  const onSubmit = (data: any) => {
    if (emailSent) {
        confirmForgotPassword(email, data.code, password, onSuccess, ErrorCallBack(email));
    } else {
        setEmail(data.email);
        forgotPassword(data.email, onSuccess, ErrorCallBack(data.email));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Toast message={toastText} open={showToast} handleClose={() => setShowToast(false)} type={toastType} />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot password
        </Typography>
        <Box 
          component="form" 
          onSubmit={(event) => {
            if (emailSent) {
              setShowError(true);
              handleSubmitAfter(onSubmit)(event)
            } else {
              handleSubmitBefore(onSubmit)(event)
            }
          }} 
          noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            autoComplete="email"
            autoFocus
            {...registerBefore('email')}
            error={errorsBefore.email ? true : false}
            helperText={errorsAfter.email?.message?.toString()}
            disabled={emailSent}
          />
          {emailSent && (
            <>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Code"
                    autoComplete="code"
                    autoFocus
                    {...registerAfter('code')}
                    error={errorsAfter.code ? true : false}
                    helperText={errorsAfter.code?.message?.toString()}
                />
                <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  required
                  fullWidth
                  error={showError && passwordRequirementsStrings.length > 0}
                />
              </FormControl>
              {passwordRequirementsStrings.length > 0 &&
                <Grid item xs={12} >
                  <PasswordRequirements requirements={passwordRequirementsStrings} />
                </Grid>
              }
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {emailSent ? "Submit" : "Send code"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
