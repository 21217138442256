import { date } from "yup";
import { getTransactions, GetTransactionsParams, Transaction } from "../Backend/SplitzUpApp/splitzUpApp";

export type DateToTransactionMap = {
    [key: string]: Transaction[]
}

export interface GroupedTransactions {
    transactions: DateToTransactionMap;
    exclusiveStartTransaction?: string;
    inProgress: string[];
}

// sort map based on date
export const sortTransactions = (transactions: DateToTransactionMap) => {
    const sortedTransactions: DateToTransactionMap = {};
    Object.keys(transactions).sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateB.getTime() - dateA.getTime();
    }).forEach(key => {
        sortedTransactions[convertDateToMonthDay(key)] = transactions[key];
    })
    return sortedTransactions;
}

export const getGroupedTransactions = async (getTransactionsParams: GetTransactionsParams): Promise<GroupedTransactions> => {
    try {
        const transactionsResponse = await getTransactions(getTransactionsParams);
        const transactions: {[key: string]: Transaction[]} = {}
        transactionsResponse.transactions.forEach(transaction => {
            const date = transaction.transactionAuthorizedDate ?? transaction.transactionDate;
            if (transactions[date]) {
                transactions[date].push(transaction);
            } else {
                transactions[date] = [transaction];
            }
        })
        console.log(transactions);
        return {
            transactions: sortTransactions(transactions),
            exclusiveStartTransaction: transactionsResponse.exclusiveStartTransaction,
            inProgress: transactionsResponse.inProgress
        }
    } catch (error) {
        throw error;
    }
}

export const mergeTransactions = (oldTransactions: DateToTransactionMap, newTransactions: DateToTransactionMap) => {
    const mergedTransactions: DateToTransactionMap = {}
    Object.entries(oldTransactions).forEach(([date, transactions]) => {
        if (date in newTransactions) {
            mergedTransactions[date] = [
                ...transactions,
                ...newTransactions[date]
            ]
            delete newTransactions[date];
        } else {
            mergedTransactions[date] = transactions
        }
    })
    Object.entries(newTransactions).forEach(([date, transactions]) => {
        mergedTransactions[date] = transactions;
    });
    return mergedTransactions;
}

export const convertDateToMonthDay = (date: string): string => {
    const dateParts = date.split("-");
    const month = months[parseInt(dateParts[1]) - 1];
    return `${month} ${dateParts[2]}, ${dateParts[0]}`;
}

const months = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const getDisplayValue = (amountString: string) => {
    const amount = parseFloat(amountString);
    return amount > 0 ? `$${amount}` :
        amount < 0 ? `+$${-1*amount}` :
        `$${amount}`;
}

export const convertISODateTimeToTime = (dateTime?: string) => {
    if (!dateTime) {
        return undefined
    }
    var time = new Date(dateTime);
    time = new Date(time.getTime() + (time.getTimezoneOffset() * 60000));
    return time.toLocaleTimeString();
}

export const getDayFromDate = (date?: string) => {
    if (!date) {
        return undefined;
    }
    return days[new Date(date).getDay()]
}

export function reverseKeyTraversal(map: DateToTransactionMap): DateToTransactionMap {
    const reversedMap: DateToTransactionMap = {};

    const keys = Object.keys(map).reverse();
    for (const key of keys) {
        reversedMap[key] = map[key];
    }

    return reversedMap;
}