import { useState } from "react";

export const useLinkToken = () => {
    const localStorageLinkToken = localStorage.getItem('linkToken');
    const localStorageLinkTokenError = localStorage.getItem('linkTokenError');

    const localStorageLinkTokenJson = localStorageLinkToken && JSON.parse(localStorageLinkToken);
    const localStorageLinkTokenErrorJson = localStorageLinkTokenError && JSON.parse(localStorageLinkTokenError);

    const initLinkToken = localStorageLinkTokenJson?.linkToken || '';
    const initLinkTokenError = localStorageLinkTokenErrorJson?.linkTokenError || '';
    const [linkTokenData, setLinkTokenData] = useState<string>(initLinkToken);
    const [linkTokenErrorData, setLinkTokenErrorData] = useState<string>(initLinkTokenError);

    const setLinkToken = (linkToken: string) => {
        setLinkTokenData(linkToken);
        localStorage.setItem('linkToken', JSON.stringify({ linkToken }));
    }

    const setLinkTokenError = (linkTokenError: string) => {
        setLinkTokenErrorData(linkTokenError);
        localStorage.setItem('linkTokenError', JSON.stringify({ linkTokenError }));
    }

    return { linkToken: linkTokenData, linkTokenError: linkTokenErrorData, setLinkToken, setLinkTokenError}
}