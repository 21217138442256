import { Queue } from "../Backend/SplitzUpApp/splitzUpApp";
import { ActionData } from "../Context/ActionContext";
import { QUEUE_NAME_FOR_REVIEW, QUEUE_NAME_FOR_REVIEWED } from "./constants";

export const sortQueues = (queues: Queue[]): Queue[] => {
    const queueForReview = queues.find(queue => queue.name === QUEUE_NAME_FOR_REVIEW);
    const queueForReviewed = queues.find(queue => queue.name === QUEUE_NAME_FOR_REVIEWED);
    const updatedQueues = queues.filter(queue => queue.name !== QUEUE_NAME_FOR_REVIEW && queue.name !== QUEUE_NAME_FOR_REVIEWED);
    let defaultQueues = [];
    if (queueForReview) {
        defaultQueues.push(queueForReview);
    }
    if (queueForReviewed) {
        defaultQueues.push(queueForReviewed);
    }
    return [...defaultQueues, ...updatedQueues];
}

export const findDefaultQueuesBreakpointIndex = (queues: Queue[]): number => {
    const queueForReview = queues.find(queue => queue.name === QUEUE_NAME_FOR_REVIEW);
    const queueForReviewed = queues.find(queue => queue.name === QUEUE_NAME_FOR_REVIEWED);
    if (queueForReview && queueForReviewed) {
        return 2;
    } else if (queueForReview || queueForReviewed) {
        return 1;
    } else {
        return 0;
    }
}

const queueMap: Record<string, string> = {
    "To Review": "Review",
}

export const transformQueueName = (queueName: string) => {
    if (queueMap[queueName]) {
        return queueMap[queueName]
    }
    return queueName
}

export const transferQueuesToActions = (queues: Queue[]): ActionData[] => queues
    .filter(queue => queue.name !== QUEUE_NAME_FOR_REVIEWED)
    .map(queue => ({
        id: queue.id,
        displayValue: transformQueueName(queue.name)
    }))


export const reverseMap = (queue: Queue) => {
    for (const [key, value] of Object.entries(queueMap)) {
        if (value === queue.name) {
            return {
                id: queue.id,
                name: key
            }
        }
    }
    return queue;
}