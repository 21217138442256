  import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
  
  export default function HomePage() {

    const navigate = useNavigate();

    useEffect(() => {
      if (localStorage && localStorage.getItem('returning-user')) {
        navigate('/Sign-in');
      } else {
        navigate('/Sign-up');
      }
    })
  
    return (
      <></>
    );
  }
  