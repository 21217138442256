import { IconButton } from "@mui/material"
import React, { useEffect } from "react";
import { getLinkToken, updateItemState } from "../Backend/SplitzUpApp/splitzUpApp";
import { useAddAccount } from "../hooks/useAddAccount";
import ErrorIcon from '@mui/icons-material/Error';

export const UpdateAccount = ({itemId}: {itemId: string}) => {

    const onSuccess = (token: string) => {
        updateItemState(itemId, "UpdateSuccess")
        .then(() => window.location.reload())
    }

    const onExit = (error: any, metadata: any) => {}

    const {open, setLinkToken} = useAddAccount(onSuccess, onExit);

    useEffect(() => {
        getLinkToken(itemId).then(token => {
            setLinkToken(token);
        }).catch(err => {
            console.error(err);
        });
    }, [itemId, setLinkToken]);
    
    return (
        <IconButton onClick={(event) => {event.stopPropagation(); open() }}>
            <ErrorIcon color='error'/>
        </IconButton>
    )
}