import createTheme, { ThemeOptions } from "@mui/material/styles/createTheme";
import componentStyleOverrides from "./compStyleOverride";

export default function createThemes() {
    const themeOptions: ThemeOptions = {
        direction: 'ltr',
        palette: {
          primary: {
            main: '#1b8381',
            light: '#219e9d',
            dark: '#085c5b',
          },
          secondary: {
            main: '#831b1d',
          },
          background: {
            paper: '#e9f3e9',
            default: '#e9f3e9',
          },
          error: {
            main: '#d32f2f',
          },
        }
      };
      
      const theme = createTheme(themeOptions);
      theme.components = componentStyleOverrides();
      return theme;
}

