import { Box, ButtonBase, Avatar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// assets
import { IconMenu2 } from '@tabler/icons';

export interface HeaderProps {
    handleLeftDrawerToggle: () => void;
    title?: string;
}

export default function Header({ handleLeftDrawerToggle, title }: HeaderProps) {
    const theme = useTheme();

    return (
        <>
          {/* logo & toggler button */}
          <Box
            sx={{
              width: 'auto',
              display: 'flex',
              marginRight: '30px',
            }}
          >
            <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
              <Avatar
                variant="rounded"
                sx={{
                  transition: 'all .2s ease-in-out',
                  background: 'inherit',
                  color: "white",
                  '&:hover': {
                    background: theme.palette.primary.dark
                  }
                }}
                onClick={handleLeftDrawerToggle}
                color="white"
              >
                <IconMenu2 stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>
    
          <Typography
              component="h1"
              variant="h6"
              color="white"
              noWrap
              sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 1 }} />
        </>
      );

}