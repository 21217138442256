import { CircularProgress, Container, CssBaseline, Grid } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { GroupQueueDetails, GroupTransactionDetails } from "./TransactionGroup"
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from "react-device-detect";
import { TransactionDetailsDrawer } from "./TransactionDetailsDrawer";
import { TransactionGroupV2 } from "./TransactionGroupV2";
import CaughtUp from "./CaughtUp";

export interface TransactionsListProps {
    transactionGroups: GroupTransactionDetails[];
    groupQueueDetails: GroupQueueDetails;
    hasMoreTransactionGroups: boolean;
    moreTransactionsLoading: boolean;
    loadMoreTransactions: () => void;
}

export default function TransactionsList(props: TransactionsListProps) {
  const [showTransaction, setShowTransaction] = useState(false)
  const [transactionGroupIndex, setTrasactionGroupIndex] = useState(0);
  const [transactionId, setTransactionId] = useState("");

  const onTransactionClick = (groupIndex: number, transactionId: string) => {
    setShowTransaction(true);
    setTrasactionGroupIndex(groupIndex);
    setTransactionId(transactionId)  
  }

  const transaction = transactionId !== "" ? props.transactionGroups[transactionGroupIndex]
    .transactionDetails.find(it => it.id === transactionId) : undefined

  const Loader = () => {
    return (
        <Grid item xs={12} sx={{ pr: 1}}>
            <CircularProgress sx={{ ml: 20}}/>
        </Grid>
    )
  }
  const TransactionGroupItem = ({index}: {index: number}) => {
    const transactionGroup = props.transactionGroups[index];
    return (
        <Grid item xs={12} key={index}>
            <TransactionGroupV2
                groupTransactionDetails={transactionGroup}
                groupQueueDetails={props.groupQueueDetails} 
                onTransactionClick={(id) => onTransactionClick(index, id)}
            />
        </Grid>
    )
  }

  const gridRef = createRef<HTMLDivElement>();
  const [gridWidth, setGridWidth] = useState(395);

  useEffect(() => {
    if (gridRef.current?.offsetWidth) {
        setGridWidth(gridRef.current?.offsetWidth)
    }
  }, [gridRef, setGridWidth])

  const isCaughtUp: boolean = !props.hasMoreTransactionGroups && props.transactionGroups.length === 0;
  
  return (
    <Container component="main" sx = {{ 
        p: '0px 1px 0px 1px',
    }}>
        <CssBaseline />
            <Grid container>
                {/* <Grid item xs={12}>
                    <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                        {!isCaughtUp && !loading && <SortOrder onSelect={(sort: string) => {props.onSortChange(sort)}}/>}
                    </Box>
                </Grid> */}
                <Grid
                    item
                    ref={gridRef} 
                    height='100%'
                    xs={12}
                >
                    {
                        isCaughtUp ?
                            <CaughtUp /> :
                            <InfiniteScroll
                                dataLength={props.transactionGroups.length}
                                next={props.loadMoreTransactions}
                                hasMore={props.hasMoreTransactionGroups}
                                loader={<Loader />}
                                // height={isMobile ? '80vh': '90vh'}
                                style={{
                                    width: gridWidth
                                }}
                            >
                                {props.transactionGroups.map((_transactionGroup, index) => 
                                    <TransactionGroupItem index={index} />
                                )}
                            </InfiniteScroll>
                    }
                </Grid>
                {showTransaction && transaction && (
                    <Grid item height="100%" width='50%'>
                        <TransactionDetailsDrawer 
                            transactionDetails={transaction}
                            open={showTransaction} 
                            onClose={() => {setShowTransaction(false); setTransactionId("")}}
                            onOpen={() => {setShowTransaction(true)}}
                            anchor={isMobile ? "bottom" : "right"}
                        />
                    </Grid>
                )}
            </Grid>
    </Container>
  )
}