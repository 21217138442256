import { Box, Divider, Grid, Paper, SwipeableDrawer, Typography } from "@mui/material"
import { Location } from "../Backend/SplitzUpApp/splitzUpApp"
import { TransactionDetails } from "./TransactionItem";

export interface TransactionDetailsDrawerProps {
    transactionDetails: TransactionDetails,
    anchor: "right" | "bottom",
    open: boolean,
    onClose: () => void;
    onOpen: () => void;
}

export const TransactionDetailsDrawer = (props: TransactionDetailsDrawerProps) => {
    const AddressComponent = (props: Location): JSX.Element => {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography variant="subtitle1" align="right">{props.address}</Typography>
                {props.city && <Typography variant="subtitle1" align="right">{props.city}</Typography>}
                {props.postal_code && <Typography variant="subtitle1" align="right">{props.postal_code}</Typography>}
            </Box>
        )
    }

    const TransactionDetails = (props: TransactionDetails) => (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} alignContent="center">
                <Paper sx={{
                    p: 2,
                    backgroundColor: 'white'
                }}>
                    <Typography
                        variant={getTypographyVariant(props.title.length)}
                        align="center"
                    >
                        {props.title}
                    </Typography>
                    <Typography
                        variant="h6"
                        align="center"
                    >
                        {props.amount}
                    </Typography>
                    {props.institutionName && (
                        <Typography
                            variant="h6"
                            align="center"
                        >
                            {props.accountName ? `${props.institutionName} - ${props.accountName}`: props.institutionName}
                        </Typography>
                    )}
                    {props.isPending === true && (
                        <Typography
                            variant="body2"
                            align="center"
                        >
                            {"Pending transaction"}
                        </Typography>
                    )}
                </Paper>
            </Grid>
            <Grid item xs={12} alignContent="center">
                <Paper sx={{
                    p: 2,
                    backgroundColor: 'white',
                    height: '100%'
                }}>
                    <Typography variant="h6" align="left">
                        Transaction details
                    </Typography>
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <Box>
                            <Typography variant="subtitle1">Date</Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle1">{props.authorizedDay ? `${props.authorizedDate}, ${props.authorizedDay}` : props.authorizedDate}</Typography>
                        </Box>
                    </Box>
                    {
                        props.authorizedTime && (
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <Box>
                                    <Typography variant="subtitle1">Time</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1">{props.authorizedTime}</Typography>
                                </Box>
                            </Box>
                        )
                    }
                    {
                        !props.isPending && (
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <Box>
                                    <Typography variant="subtitle1">Posted Date</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1">{props.postedDate}</Typography>
                                </Box>
                            </Box>
                        )
                    }
                    {
                        props.address && props.address.address && (
                            <>
                                <Divider />
                                <Box sx={{ 
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <Box>
                                        <Typography variant="subtitle1">Address</Typography>
                                    </Box>
                                    <Box>
                                        <AddressComponent {...props.address} />
                                    </Box>
                                </Box>
                            </>
                        )
                    }
                    {
                        props.merchantName && (
                            <>
                                <Divider />
                                <Box sx={{ 
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <Box>
                                        <Typography variant="subtitle1">Merchant</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle1">{props.merchantName}</Typography>
                                    </Box>
                                </Box>
                                {
                                    props.methodType && (
                                        <Box sx={{ 
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <Box>
                                                <Typography variant="subtitle1">Method</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle1">{props.methodType}</Typography>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </>
                        )
                    }
                </Paper>
            </Grid>
        </Grid>
    )

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
        <SwipeableDrawer
            anchor={props.anchor}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
        >
            <Box sx={{
                mt: props.anchor === "bottom" ? '16px' : '76px',
                ml: '16px',
                mr: '16px',
                mb: props.anchor === "bottom" ? '16px' : '0px',
                maxWidth: '620px',
                maxHeight: iOS ? '60vh' : undefined
            }}>
                <TransactionDetails {...props.transactionDetails} />
            </Box>
        </SwipeableDrawer>
    )
}

function getTypographyVariant(length: number) {
    if (length < 21) {
        return "h4"
    } if (length < 42) {
        return "h5"
    } 
    return "h6"
}
