import { AppBar, Box, CssBaseline, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { buildMenuItems } from '../../Common/navigation';
import { ActionContext } from '../../Context/ActionContext';
import Header from '../Header';
import { PrivateRoute } from '../PrivateRoute';
import Sidebar from '../SideBar';
import { ShellProps } from './interface';

interface MainProps {
  open: boolean;
  isPublicRoute: boolean;
}

const mainContent = {
  backgroundColor: 'white',
  width: '100%',
  minHeight: 'calc(100vh - 88px)',
  flexGrow: 1,
  padding: '10px',
  marginRight: '20px',
  borderRadius: `12px`
};

const drawerWidth = 260;
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'left' })
<MainProps>(({ theme, open, isPublicRoute }) => ({
...mainContent,
marginTop: isPublicRoute ? '20px': '68px',
borderBottomLeftRadius: 0,
borderBottomRightRadius: 0,
transition: theme.transitions.create(
  'margin',
  open
    ? {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }
    : {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }
),
[theme.breakpoints.up('md')]: {
  marginLeft: isPublicRoute 
    ? '20px' 
    : open ? 0 : -(drawerWidth - 20),
  width: `calc(100% - ${drawerWidth}px)`
},
[theme.breakpoints.down('md')]: {
  marginLeft: '20px',
  width: `calc(100% - ${drawerWidth}px)`,
  // padding: '16px'
},
[theme.breakpoints.down('sm')]: {
  marginLeft: '10px',
  width: `calc(100% - ${drawerWidth}px)`,
  // padding: '16px',
  marginRight: '10px'
}
}));

export default function Shell(props: ShellProps) {
    const theme = useTheme();
    const [leftDrawerOpened, setLeftDrawerOpened] = useState(false);
    const toggleDrawer = () => {
      setLeftDrawerOpened(!leftDrawerOpened);
    };
    const {actions} = useContext(ActionContext);
    const menuItems = buildMenuItems(actions);
    return (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {/* header */}
          {!props.isPublicRoute && 
            <>
              <AppBar
                  position="fixed"
                  color="inherit"
                  elevation={0}
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                  }}
              >
                  <Toolbar>
                    <Header handleLeftDrawerToggle={toggleDrawer} title={props.title}/>
                  </Toolbar>
              </AppBar>

              <Sidebar 
                drawerOpen={leftDrawerOpened}
                drawerToggle={toggleDrawer}
                menuItems={menuItems}
              />
            </>
          }
          <Main theme={theme} open={leftDrawerOpened} isPublicRoute={props.isPublicRoute === true} >
            {props.isPublicRoute ? <Outlet /> :
              <PrivateRoute>
                <Outlet />
              </PrivateRoute>
            }
          </Main>
        </Box>
    );
};