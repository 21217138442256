import { Alert, Box, Button, Container, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Account, getAccounts } from "../Backend/SplitzUpApp/splitzUpApp";
import { AddAccount } from "./AddAccount";
import { Institution, InstitutionsList } from "./InstitutionList";
import Toast from "./Toast";

export default function AddAccountHome(props: {
    onComplete?: () => void;
    descriptionText: string;
    showNextButton?: boolean;
}) {
    const [institutionFetchState, setInstitutionFetchState] = useState<string>("Init");
    const [institutions, setInstitutions] = useState<Institution[]>([]);
    const [toastText, setToastText] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState<"info" | "success">("info");
    const [autoHideDuration, setAutoHideDuration] = useState(5000);

    useEffect(() => {
        setInstitutionFetchState("Fetching");
        getAccounts().then(accounts => {
            const instituions = convertAccountToInstitutions(accounts);
            setInstitutions(instituions);
            setInstitutionFetchState("Success");
        }).catch(error => {
            setInstitutionFetchState("Error");
        });
    }, [setInstitutionFetchState]);

    const onStartCallback = () => {
        setToastText("Adding your account...");
        setAutoHideDuration(10000);
        setShowToast(true);
    }

    const onSuccessCallback = () => {
        setToastText("Account added successfully");
        setToastType("success");
        setAutoHideDuration(5000);
        setShowToast(true);
        setInstitutionFetchState("Fetching");
        getAccounts().then(accounts => {
            const instituions = convertAccountToInstitutions(accounts);
            setInstitutions(instituions);
            setInstitutionFetchState("Success");
        })
    }

    return (
        <Box>
            <Container maxWidth="sm">
                <Toast message={toastText} open={showToast} handleClose={() => setShowToast(false)} type={toastType} autoHideDuration={autoHideDuration} />
                <Typography
                    component="h2"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Connect Accounts
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                    {props.descriptionText}
                </Typography>
                {institutions.length > 0 && <Box>
                    <InstitutionsList
                        fetchState={institutionFetchState}
                        institutions={institutions} 
                        onAccountClick={() => {}}
                        onDeleteItemClick={() => {}}                        
                     />
                </Box>}
                <Stack
                    sx={{ pt: 4, pb: 4 }}
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    >
                    <AddAccount onStartCallback={onStartCallback} onSuccessCallback={onSuccessCallback} allow={institutions.length < 2}/>
                    {props.showNextButton && institutions.length > 0 && <Button variant="outlined" onClick={props.onComplete}>Next</Button>}
                </Stack>
                <Alert severity="info">
                    To remove an account, contact splitzup.net+support@gmail.com
                </Alert>
            </Container>
        </Box>
    )
}

export const convertAccountToInstitutions = (accounts: Account[]): Institution[] => {
    const institionMap = new Map<string, Institution>();
    accounts.forEach(account => {
        const institutionDetails = institionMap.get(account.institution.id);
        if (!institutionDetails) {
            institionMap.set(account.institution.id, {
                id: account.institution.id,
                name: account.institution.name,
                logo: account.institution.logo,
                itemState: account.institution.itemState,
                accounts: [{
                    id: account.id,
                    name: account.name
                }]
            });
        } else {
            institionMap.set(account.institution.id, {
                ...institutionDetails,
                accounts: [...institutionDetails.accounts, {
                    id: account.id,
                    name: account.name
                }]
            });
        }
    });

    const institutions: Institution[] = [];
    institionMap.forEach(institution => {
        if (institution.itemState !== "Deleting") {
            institutions.push(institution);
        }
    });

    return institutions;
}