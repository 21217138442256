import { useUser } from "../hooks/useUser";
import { AuthContext } from "./AuthContext";

export const AuthContextProvider = (props: any) => {
    const {user, setUser, removeUser} = useUser();
    return (
        <AuthContext.Provider value={{ user, setUser, removeUser }}>
            {props.children}
        </AuthContext.Provider>
    )
}