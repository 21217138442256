import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAccounts, getQueues, Queue, Transaction, updateTransaction } from "../Backend/SplitzUpApp/splitzUpApp";
import { GroupTransactionDetails } from "../Components/TransactionGroup";
import TransactionsList from "../Components/TransactionsList";
import { sortQueues } from "../utils/queueUtils";
import { convertDateToMonthDay, convertISODateTimeToTime, DateToTransactionMap, getDayFromDate, getDisplayValue, getGroupedTransactions, mergeTransactions, reverseKeyTraversal } from "../utils/transactionsUtils";

export default function Transactions() {
    const [transactions, setTransactions] = useState<DateToTransactionMap>({});
    const [queues, setQueues] = useState<Queue[]>([]);
    const [fetchState, setFetchState] = useState("Init");
    const [exclusiveStartTransaction, setExclusiveStartTransaction] = useState<string | undefined>(undefined);
    const [desc, setDesc] = useState<boolean>(true);
    var transactionGroup: GroupTransactionDetails[] = Object.entries(transactions).map(([date, transactions]) => {
        return {
            date,
            transactionDetails: transactions.map(transaction => ({
                id: transaction.transactionId,
                amount: getDisplayValue(transaction.transactionAmount),
                institutionName: transaction.transactionInstitutionName,
                accountName: transaction.transactionAccount,
                title: transaction.transactionTitle,
                defaultQueue: transaction.transactionQueue,
                authorizedDate: convertDateToMonthDay(transaction.transactionAuthorizedDate ?? transaction.transactionDate),
                postedDate: transaction.transactionPostedDate ? convertDateToMonthDay(transaction.transactionPostedDate) : convertDateToMonthDay(transaction.transactionDate),
                address: transaction.transactionLocation,
                isPending: transaction.transactionIsPending,
                authorizedTime: convertISODateTimeToTime(transaction.transactionAuthorizedDateTime),
                authorizedDay: getDayFromDate(transaction.transactionAuthorizedDate),
                merchantName: transaction.transactionMerchantName,
                methodType: transaction.transactionPaymentChannel
            }))
        }
    });

    const [serachParams] = useSearchParams();

    const itemId = serachParams.get("itemId") || "";
    const queueId = serachParams.get("queueId") || "";
    const accountId = serachParams.get("accountId") || "";

    // fetch accounts and if it's length is zero then navigate to institutions page
    useEffect(() => {
        getAccounts().then(result => {
            if (result.length === 0) {
                window.location.href = "/Institutions";
            }
        })
    }, []);

    useEffect(() => {
        setFetchState("Fetching");
        getGroupedTransactions({
            itemId,
            queueId,
            accountId
        }).then(result => {
            setTransactions(result.transactions);
            setExclusiveStartTransaction(result.exclusiveStartTransaction);
            setFetchState("Success");
        })
        getQueues().then(result => {
            let queues = result.queues;
            if (queueId) {
                queues = queues.filter(queue => queue.id !== queueId);
            }
            setQueues(sortQueues(queues));
        });
    }, [setTransactions, itemId, accountId, queueId])

    const onQueueChange = (date: string, transactionId: string, queue: Queue) => {
        updateTransaction(transactionId, queue.id).then(result => {
            const updatedTrasactions: DateToTransactionMap = {
                ...transactions
            }
            if (!queueId) {
                const affectedTransaction: Transaction | undefined = transactions[date].find(transaction => transaction.transactionId === transactionId);
                if (affectedTransaction) {
                    affectedTransaction.transactionQueue = queue.name;
                }
            } else {
                /* remove transaction if it's queue transactions view */
                const updatedDateTransactions = transactions[date].filter(transaction => transaction.transactionId !== transactionId);
                if (updatedDateTransactions.length === 0) {
                    delete updatedTrasactions[date];
                } else {
                    updatedTrasactions[date] = updatedDateTransactions;
                }
            }
            setTransactions(updatedTrasactions);
        })
    }

    const groupQueueDetails = {
        queues,
        onQueueChange
    }

    const loadMoreTransactions = () => {
        if (exclusiveStartTransaction) {
            setFetchState("Fetching");
            getGroupedTransactions({
                itemId,
                queueId,
                accountId,
                exclusiveStartTransaction
            }).then(result => {
                const mergedTransactions = mergeTransactions(transactions, result.transactions);
                setTransactions(mergedTransactions);
                setExclusiveStartTransaction(result.exclusiveStartTransaction);
                setFetchState("Success");
            })
        }
    }

    return (
        <TransactionsList
            transactionGroups={transactionGroup}
            groupQueueDetails={groupQueueDetails} 
            hasMoreTransactionGroups={fetchState !== "Success" || !!exclusiveStartTransaction} 
            moreTransactionsLoading={fetchState !== "Success"} 
            loadMoreTransactions={loadMoreTransactions}
        />
    )
}