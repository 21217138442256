import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthContext, isUserSessionExpired, User } from "../Context/AuthContext";
import { authenticateUser, resendConfirmationCode } from "../Backend/cognito";
import { useFormValidator, ValidationFormType } from "../hooks/useFormValidator";
import Toast from "./Toast";
import { LimitExceededError, NotAuthorizedError, UserNotConfirmedError, UserNotFoundError } from "../Error/cognito";

export default function SignIn() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const { register, handleSubmit, errors } = useFormValidator(ValidationFormType.SIGN_IN);

  // Get the target URL from the query parameter and decode it
  const [serachParams] = useSearchParams();
  const encodedUrl = serachParams.get('redirect-url');
  const targetUrl = encodedUrl ? decodeURIComponent(encodedUrl) : undefined;

  const [errorToastText, setErrorToastText] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const onSuccess = (session: CognitoUserSession) => {
    const user: User = {
        idToken: session.getIdToken().getJwtToken(),
        name: session.getIdToken().payload.name,
        email: session.getIdToken().payload.email,
        expiryTime: (session.getIdToken().payload.exp*1000).toString()
      }
      setUser(user);
      if (targetUrl) {
        window.location.replace(targetUrl);
      } else {
        navigate("/Inbox");
      }
  }

  const ErrorCallBack = (email: string) => {
    return (err: Error) => {
      if (err instanceof LimitExceededError) {
        setErrorToastText("Limit exceeded! Please try again after sometime");
        setShowErrorToast(true);
      } else if (err instanceof UserNotConfirmedError) {
        setErrorToastText("Please verify your email");
        setShowErrorToast(true);
        resendConfirmationCode(email, () => {}, () => {});
        setTimeout(() => {
          navigate("/Verification", {
            state: {
              email,
            },
          });
        }, 2000);
      } else if (err instanceof UserNotFoundError) {
        setErrorToastText("No user with this email address found");
        setShowErrorToast(true);
      } else if (err instanceof NotAuthorizedError) {
        setErrorToastText("Password incorrect! Pleast try again!");
        setShowErrorToast(true);
      } else {
        setErrorToastText("Server Error");
        setShowErrorToast(true);
      }
    }
  }

  const onSubmit = (data: any) => {
    authenticateUser(data.email, data.password, onSuccess, ErrorCallBack(data.email));
  };

  useEffect(() => {
    localStorage.setItem('returning-user', '1');
    if (user && user.expiryTime && !isUserSessionExpired(user.expiryTime)) {
      if (targetUrl) {
        window.location.replace(targetUrl);
      } else {
        navigate("/Inbox");
      }
    }
  }, [user, navigate, targetUrl]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Toast message={errorToastText} open={showErrorToast} handleClose={() => setShowErrorToast(false)} type="error" />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} src="LogoRound.png" />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            autoComplete="email"
            autoFocus
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email?.message?.toString()}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register('password')}
            error={errors.password ? true : false}
            helperText={errors.password?.message?.toString()}
          />
          <Grid container justifyContent={"flex-end"}>
            <Grid item xs>
              <Link href="/Forgot-Password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/Sign-up" variant="body2">
                Don't have an account? Sign up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
