import { CssBaseline, ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import ForgotPassword from './Components/ForgotPassword';
import { PrivateRoute } from './Components/PrivateRoute';
import SignIn from './Components/SignIn';
import SignUp from './Components/SignUp';
import Verification from './Components/Verification';
import { AuthContextProvider } from './Context/AuthContextProvider';
import HomePage from './Pages/HomePage';
import Institutions from './Pages/Institutions';
import Queues from './Pages/Queues';
import Transactions from './Pages/Transactions';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './utils/constants';
import createThemes from './Theme';
import Shell from './Components/Shell';
import { ActionContextProvider } from './Context/ActionContextProvider';
import Onboarding from './Pages/Onboarding';
import AccountsSettings from './Pages/AccountsSettings';
import ActionsSettings from './Pages/ActionsSettings';
import Logout from './Pages/Logout';

function App() {
  const theme = createThemes();
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <AuthContextProvider>
        <ActionContextProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
              <Routes>
                <Route path="/" element={<Shell isPublicRoute={true}/>}>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/Sign-up" element={<SignUp />} />
                  <Route path="/Verification" element={<Verification />} />
                  <Route path="/Sign-in" element={<SignIn />} />
                  <Route path="/Forgot-Password" element={<ForgotPassword />} />
                </Route>
                <Route path="/" element={<Shell title={"SplitzUp App"}/>}>
                  {/* <Route path="/Home" element={<Home/>}></Route> */}
                  <Route path="/Inbox" element={<Transactions/>}></Route>
                  <Route path="/Onboarding" element={<Onboarding/>}></Route>
                  <Route path="/Settings/Accounts" element={<AccountsSettings/>}></Route>
                  <Route path="/Settings/Actions" element={<ActionsSettings/>}></Route>
                  <Route path="/Logout" element={<Logout/>}></Route>
                </Route>  
                <Route path="/" element={<PrivateRoute />} >
                  <Route path="/Institutions" element={<Institutions />} />
                </Route>
                <Route path="/Institutions" element={
                  <PrivateRoute>
                    <Institutions />
                  </PrivateRoute>
                } />
                <Route path="/Transactions" element={
                  <PrivateRoute>
                    <Transactions />
                  </PrivateRoute>
                } />
                <Route path="/Queues" element={
                  <PrivateRoute>
                    <Queues />
                  </PrivateRoute>
                } />
              </Routes>
          </ThemeProvider>
        </ActionContextProvider>
      </AuthContextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
