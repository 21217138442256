import { Chip, Divider, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Queue } from "../Backend/SplitzUpApp/splitzUpApp";
import { QUEUE_NAME_FOR_REVIEW, QUEUE_NAME_FOR_REVIEWED } from "../utils/constants";
import { findDefaultQueuesBreakpointIndex } from "../utils/queueUtils";

interface QueueSelectorArgs {
    defaultValue?: string;
    queues: Queue[];
    onChange: (target: Queue) => void;
}

export default function QueueSelector({
    defaultValue,
    queues,
    onChange
}: QueueSelectorArgs) {

    const amendedQueues = queues.filter(queue => queue.name !== defaultValue);
    const defaultQueuesBreakpointIndex = findDefaultQueuesBreakpointIndex(amendedQueues);
    const defaultQueues = amendedQueues.slice(0, defaultQueuesBreakpointIndex);
    const otherQueues = amendedQueues.slice(defaultQueuesBreakpointIndex);
    
    const [value, setValue] = useState(defaultValue);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
      };

    const handleQueueMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const queueSelect = (event: React.MouseEvent<HTMLElement>, queue: Queue) => {
        event.stopPropagation()
        onChange(queue);
        setValue(queue.id === "" ? "" : queue.name);
        handleMenuClose(event);
    }

    const displayValue = value === QUEUE_NAME_FOR_REVIEW ? "" : value;

    const displayDropDownArrow: Boolean = value === undefined || value === QUEUE_NAME_FOR_REVIEW;
    const dropDownProps = {
        ...(displayDropDownArrow && {
            deleteIcon: <KeyboardArrowDownIcon />,
            onDeleteClick: handleQueueMenuOpen
        }),
    }

    const getChipColor = (queueName?: string) => {
        if (queueName === QUEUE_NAME_FOR_REVIEWED) {
            return "success";
        } else if (queueName === QUEUE_NAME_FOR_REVIEW) {
            return "info";
        } else {
            return "default";
        }
    }

    const renderQueueMenus = (queues: Queue[]) => (
        queues.map(queue => 
            <MenuItem 
                key={queue.id} 
                onClick={(e) => {queueSelect(e, queue)}} 
            >
                <Chip label={queue.name} color={getChipColor(queue.name)} />
            </MenuItem>
        )
    )

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* {renderQueueMenus(defaultQueues)}
            {defaultQueues.length > 0 && otherQueues.length > 0 && <Divider/>}
            {renderQueueMenus(otherQueues)} */}
            {renderQueueMenus(queues.filter(queue => queue.name !== "Reviewed"))}
        </Menu>
      );
    return (
        <>
            <Chip label={displayValue}
                color={getChipColor(displayValue)}
                onClick={handleQueueMenuOpen}
                sx={{
                    "& .MuiChip-label": {
                        p: '0px 6px 0px 6px'
                    },
                    m: '4px 0px 4px 0px'
                }}
                onDelete={dropDownProps.onDeleteClick}
                deleteIcon={dropDownProps.deleteIcon}
            />
            {renderMenu}
        </>
    )
}