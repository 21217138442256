import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getOnboardingState, setOnboardingState } from "../Backend/SplitzUpApp/Onboarding";
import AddAccountHome from "../Components/AddAccountHome";
import AddActions from "../Components/AddActions";
import { ADD_ACCOUNT_DESCRIPTION_ONBOARDING } from "../utils/constants";

export default function Onboarding() {
    const state = useLocation().state;
    const [nextStep, setNextStep] = useState<string | undefined>(state?.nextStep);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (nextStep !== undefined) {
            return;
        }
        
        getOnboardingState().then(response => {
            if (response.isComplete) {
                navigate("/Inbox");
            }
            if (response.nextStep === undefined) {
                setNextStep("AddAccounts");
            } else {
                setNextStep(response.nextStep);
            }
        });
    }, [setNextStep]);

    const onStepComplete = (step: string) => {
        setOnboardingState({
            completedStep: step
        }).then(response => {
          if (response.isComplete) {
            navigate("/Inbox");
          }
          setNextStep(response.nextStep);  
        })
    }

    return (
        <Grid container spacing={3}>
            {nextStep === "AddAccounts" && (
                <Grid item xs={12}>
                    <AddAccountHome 
                        onComplete={() => onStepComplete("AddAccounts")}
                        descriptionText={ADD_ACCOUNT_DESCRIPTION_ONBOARDING}
                        showNextButton={true}
                    />
                </Grid >
            )}
            {nextStep === "AddActions" && (
                <Grid item xs={12}>
                    <AddActions onComplete={() => onStepComplete("AddActions")} showNext={true}/>
                </Grid>
            )}
        </Grid>
    )
}