import { useActions } from "../hooks/useActions";
import { ActionContext } from "./ActionContext";

export const ActionContextProvider = (props: any) => {
    const { actions, setActions} = useActions();
    return (
        <ActionContext.Provider value={{ actions, setActions }}>
            {props.children}
        </ActionContext.Provider>
    )
}