import {
    Box,
    Card,
    CardHeader,
    Container,
    CssBaseline,
    Divider,
    List,
    ListItemButton,
    ListItemText} from '@mui/material';
  import React from 'react';
import { Queue } from '../Backend/SplitzUpApp/splitzUpApp';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { findDefaultQueuesBreakpointIndex } from '../utils/queueUtils';

export interface QueuesListProps {
    fetchState: string;
    queues: Queue[];
    onQueueClick: (queueId: string) => void;
  }
  
  export const QueuesList = (props: QueuesListProps): JSX.Element => {
    const { queues, onQueueClick } = props;
    const defaultQueuesBreakpointIndex = findDefaultQueuesBreakpointIndex(queues);
    const defaultQueues = queues.slice(0, defaultQueuesBreakpointIndex);
    const otherQueues = queues.slice(defaultQueuesBreakpointIndex);

    const length = queues.length;

    const renderQueneNames = (queues: Queue[]) => (
      queues.map((queue: Queue, index) => {
        return (
          <React.Fragment key={queue.id}>
            <ListItemButton 
              key={queue.id}
              alignItems="flex-start" 
              onClick={() => {onQueueClick(queue.id)}}
              sx={{ p: 0 }}
          >
              <ListItemText
                primary={queue.name}
                primaryTypographyProps={{ variant: 'subtitle1' }}
                sx={{ pl: 4 }}
              />
              <Box alignContent={"center"}
                  sx={{
                      p: '6px 6px 0px 6px'
                  }}
              >
                  <KeyboardArrowRightIcon />
              </Box>
              
            </ListItemButton>
            {index < length - 1 && <Box sx={{ height: 1, bgcolor: 'grey.300' }} />}
          </React.Fragment>
        );
      })
    );
    
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Card sx={{
            marginTop: 8,
            alignItems: "center",
            borderRadius: 3
          }}>
            <CardHeader title="Queues" align="center" />
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'white', color: 'black'}}>
              {renderQueneNames(defaultQueues)}
              {otherQueues.length > 0 && <Divider />}
              {renderQueneNames(otherQueues)}
          </List>
        </Card>
      </Container>
    );
  };