import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signUp } from "../Backend/cognito";
import PasswordRequirements from "./PasswordRequirements";
import { Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { usePasswordRequirements } from "../hooks/usePasswordRequirements";
import { useFormValidator, ValidationFormType } from "../hooks/useFormValidator";
import Toast from "./Toast";
import { UserAlreadyExistsError } from "../Error/cognito";

export default function SignUp() {
  const [serachParams] = useSearchParams();
  const emailId = serachParams.get("emailId") || undefined;

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {register, handleSubmit, errors} = useFormValidator(ValidationFormType.SIGN_UP);
  const {password, setPassword, passwordRequirementsStrings} = usePasswordRequirements();
  const [showError, setShowError] = useState(false);

  const [errorToastText, setErrorToastText] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const successCallBack = (email: string, password: string) => {
    return () => {
      navigate("/Verification", {
        state: {
          email,
          password
        },
      });
    }
  }

  const onError = (err: Error) => {
    if (err instanceof UserAlreadyExistsError) {
      setErrorToastText("User with this email address already exists");
      setShowErrorToast(true);
    } else {
      setErrorToastText("Server Error");
      setShowErrorToast(true);
    }
  }
  
  const onSubmit = (data: any) => {
    if (passwordRequirementsStrings.length === 0) {
      signUp(data.email, password, "First", "Last", successCallBack(data.email, password), onError);
    }
  }

  // const [isChecked, setIsChecked] = useState(false);

  // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsChecked(event.target.checked);
  // };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Toast message={errorToastText} open={showErrorToast} handleClose={() => setShowErrorToast(false)} type="error" />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} src="LogoRound.png" />
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box 
          component="form" noValidate 
          onSubmit={(event) => {setShowError(true); handleSubmit(onSubmit)(event)}} sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus={emailId === undefined}
                defaultValue={emailId}
                {...register('email')}
                error={errors.email ? true : false}
                helperText={errors.email?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => setPassword(event.target.value)}
                  name="password"
                  autoFocus={emailId !== undefined}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  required
                  fullWidth
                  error={showError && passwordRequirementsStrings.length > 0}
                />
              </FormControl>
            </Grid>
            {showError && passwordRequirementsStrings.length > 0 &&
              <Grid item xs={12} >
                <PasswordRequirements requirements={passwordRequirementsStrings} />
              </Grid>
            }
          </Grid>
          {/* <FormControlLabel
            sx={{ mt: 1 }}
            control={<Checkbox value="agreement" color="primary" onChange={handleCheckboxChange}/>}
            label={
              <Typography variant="body2" color="text.secondary" align="left">
                By signing up you agree to our <a href="https://splitzup.net/terms-of-use.html" target="_blank">terms of use</a> and <a href="https://splitzup.net/privacy-policy.html" target="_blank">privacy policy</a>
              </Typography>
              }
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            // disabled={!isChecked}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/Sign-in" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}